import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useSelector} from "react-redux";
import {selectProfile} from "@features/user/userSlice";
import {useAppSelector} from "@app/hooks";
import { Button, ImageUploader, Toast } from "antd-mobile";
import { CheckCircleIcon, InformationTriangleIcon } from "@share/icons";
import UploadImage from "@share/components/UploadImage";
import { mockUpload } from "@share/lib";
import moment from "moment";
import { saveAs } from 'file-saver'
import { updateCampaignImage } from "@share/api";

export default function UploadTestImage(props) {
  const { t } = useTranslation();
  const {details, setShowUploadImage} = props
  const [valueUrlImage, setValueUrlImage] = useState(details?.acceptance_image || '');
  const [uploadedImageArr, setUploadedImageArr] = useState([]);
  const [errorImage, setErrorImage] = useState('');
  const [isButton, setIsButton] = useState(false)

  const contentCss = 'text-[15px] font-normal text-[#666666]'
  const buttonCss ='adm-button adm-button-shape-default w-full w-1/2 text-center'

  const handleSave = async () => {
    if(!valueUrlImage) {
      setErrorImage(t('ss_builder_upload_file_format_invalid_content'))
      setIsButton(true)
      return
    }
    const imageUpload = uploadedImageArr && uploadedImageArr[uploadedImageArr.length - 1];
    const formData = new FormData();
    if(imageUpload) formData.append('acceptance_image', imageUpload?.file);
    formData.append('cpc_campaign_id', details.id);
    const res = await updateCampaignImage(formData)
    if (res) {
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block w-[36px] h-[36px]"
            fillColor="#00B578"
          />
        ),
        content: <div className="text-center">{t("ss_brand_cpc_upload_image_successfully")}</div>,
      });
      setTimeout(() => {
        setShowUploadImage(false)
      }, 1500)
    }
  }
  
  useEffect(() => {
    if(errorImage) {
      setUploadedImageArr([])
      setValueUrlImage('')
    }
  },[errorImage])

  const handleImageUrl = (data) => {
    const imgFileItems = data && data[data.length - 1];
    if (imgFileItems?.isValidFormat) {
      setErrorImage(t("ss_builder_upload_file_format_invalid_content"))
    } else if (imgFileItems?.isValidSize) {
      setErrorImage(t("ss_builder_upload_file_size_invalid_content"))
    } else {
      setUploadedImageArr(data);
      setValueUrlImage(imgFileItems.url);
      setIsButton(true)
      setErrorImage('')
    }
  };
  
  const handleDownloadBarCode = async () => {
    const res = await fetch('https://ga.passio.eco/api/v1/affiliate/media-file?tk=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmaWxlIjoiaW1hZ2VzL2NvbW1vbi9mXzIwMjMwNDEyMTEzNDEwMTY2MTMxMDAucG5nIiwiZXh0IjoicG5nIiwidHlwZSI6ImltYWdlIn0.VfCq2uV1TaeZ8WmpwyLB2ReW9IO0wCwFQmbwCBeIh9I')
    const data = await res.blob()
    const url = window.URL.createObjectURL(data)
    let a = document.createElement('a')
    a.href = url
    a.download = Date.now() + '-file.png'
    a.click()
  }
  const handleDownloadDantri = async () => {
    const res = await fetch('https://icdn.dantri.com.vn/thumb_w/1020/2023/05/31/luc-luong-than-nga-tai-donbass-don-them-hai-tieu-doan-tiep-viendocx-1663383975320-1668568569427-1673146469826-1676283542589-1685517987879.jpeg')
    const data = await res.blob()
    const url = window.URL.createObjectURL(data)
    let a = document.createElement('a')
    a.href = url
    a.download = Date.now() + '-file.png'
    a.click()
  }

  const handleDownload = async () => {
    const res = await fetch(details.creator_banner_image)
    const data = await res.blob()
    const url = window.URL.createObjectURL(data)
    let a = document.createElement('a')
    a.href = url
    a.download = Date.now() + '-file.png'
    a.click()
    // saveAs(url, `banner-title.png`)
  }

  const toDataURL = (url) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }
  const dateTime = () => {
    if (details.creator_start_date && details.creator_end_date) {
      return (
        `${moment(details?.creator_start_date).format('DD/MM/YYYY')} - ${moment(details?.creator_end_date).format('DD/MM/YYYY')}`
      )
    }
    if (details.creator_start_date && !details.creator_end_date) {
      return (
          `${t('ss_brand_cpc_from')} ${moment(details?.creator_start_date).format('DD/MM/YYYY')}`
      )
    }
    if (!details.creator_start_date && details.creator_end_date) {
      return (
          `${t('ss_brand_cpc_to')} ${moment(details?.creator_end_date).format('DD/MM/YYYY')}`
      )
    }
  }
  
  return (
    <div>
      <div className="w-full h-full pb-[69px]">
        <div className="py-3 px-3 bg-white pb-2 flex" style={{boxShadow: "inset 0px -1px 0px #EEEEEE",}}>
          <span className="m-auto text-center items-center text-lg font-normal">{t("ss_brand_cpc_upload_image_title")}</span>
        </div>

        <div className="flex flex-col gap-3 ">
          <div className="flex flex-col gap-1 px-4 py-3 bg-white">
            <div className="flex flex-col gap-0.5 relative">
              <div className="absolute top-0 text-[15px] leading-6" style={{left: '-0.6em', color: 'var(--adm-color-danger)'}}>*</div>
              <div className={`${contentCss}`}>{t('ss_brand_cpc_step_1')}</div>
              <div className={`${contentCss}`}>
                {t('ss_brand_cpc_step_1_note')}
                <br/>
                {dateTime()}
              </div>
            </div>
            <img src={details.creator_banner_image} style={{aspectRatio: '3/2'}}/>
            <div className="mt-3">
              <Button color="primary" fill="solid" size="middle" className="w-full" onClick={handleDownload}>
                {t("ss_brand_cpc_download")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-1 px-4 py-3 bg-white">
            <div className="flex flex-col gap-0.5 relative">
              <div className="absolute top-0 text-[15px] leading-6" style={{left: '-0.6em', color: 'var(--adm-color-danger)'}}>*</div>
              <div className={`${contentCss}`}>{t('ss_brand_cpc_step_2')}</div>
              <div className={`${contentCss}`}>{t('ss_brand_cpc_step_2_note')}</div>
            </div>
            <UploadImage
              isUseBorderErr={errorImage.length > 0}
              valueUrlImage={valueUrlImage}
              imageRatio={'3/2'}
              error={errorImage.length > 0}
              textHelper={t('ss_tod_setting_cover_image_helper')}
            />
            {errorImage && (
              <div className="flex flex-row gap-1 items-center mt-1">
                <InformationTriangleIcon fillColor="#FF3141" className="w-4 h-4"/>
                <div className="text-[#FF3141] text-[13px]">{errorImage}</div>
              </div>
            )}

            <div className="mt-3">
              <ImageUploader
                onChange={handleImageUrl}
                upload={(file) => mockUpload(file, 514, 980)}
                className="grow"
                value={uploadedImageArr}
                disableUpload={details.join_status === 2}
                children={
                  <Button
                    color="primary"
                    fill="solid"
                    size="middle"
                    className="w-full"
                    disabled={details.join_status === 2}
                  >{t("ss_brand_cpc_upload_image_button")}
                  </Button>
                }
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className="max-width-content-class fixed bottom-0 p-2 flex w-full justify-between gap-2 z-[10] bg-[#FFFFFF]">
        <Button className={`${buttonCss} adm-button-default`} onClick={() => setShowUploadImage(false)}>
          {t("ss_builder_close_panel")}
        </Button>
        <Button
          disabled={!isButton}
          color="primary"
          className={`${buttonCss} adm-button-primary`}
          onClick={handleSave}
        >
          {t("ss_builder_save")}
        </Button>
      </div>
    </div>
  )
}