import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import PositionInfo from "@features/design/components/PositionInfo";
import { disableEnablePosition } from "@features/main/store/positionsSlice";
import {
  BLOCK_NOT_DISPLAY,
  BLOCK_SETTING_DEFAULT_VALUE,
  COUNTRY_TH,
  TAB_GHIM_STYLE,
} from "@share/configs/const";
import { InfomationPositionIcon } from "@share/icons";
import gaEvents, { ACTION_CONFIG, CATEGORY_CONFIG } from "@share/lib/ga-events";
import { Divider, Switch } from "antd-mobile";
import cls from "classnames";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import { useMemo } from "react";
import { Draggable, Droppable, resetServerContext } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { PRODUCT_TOUR_CLASS_NAME } from "../../ProductTour/product-tour-config";
import MappingTypeComponent from "../../MappingTypeComponent";
import PlaceholderCustom from "../PlaceholderCustom";
import EmptyBlock from "../../EmptyBlock";
import BlockDraggingSpace from "../BlockDraggingSpace";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import { COUNTRY_TH_BLOCK_HIDDEN } from "@features/block/config/block-selection";

type Props = {
  headerState: any;
  headingDrag: boolean;
  addNewBlockButtonDrag: boolean;
  placeholderProps: any;
};

const HeaderDesign = (props: Props) => {
  const { headerState, headingDrag, addNewBlockButtonDrag, placeholderProps } =
    props;
  const block_style = useAppSelector(
    (state) => state.siteSettings?.display_setting?.block_setting?.block_style
  );
  const country = useSelector((state: RootState) => state?.user?.kol?.country);
  const {
    block_space = BLOCK_SETTING_DEFAULT_VALUE.BLOCK_SPACE,
    outside_space,
  } = block_style ?? {};

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const listBlocksSortOrder = useMemo(() => {
    const listBlocksData =
      country === COUNTRY_TH
        ? headerState?.block_list?.filter(
            (block) => !COUNTRY_TH_BLOCK_HIDDEN.includes(block?.block_type)
          )
        : headerState?.block_list;
    const newListBlocksData =
      listBlocksData && orderBy(listBlocksData, ["sort_order"], ["asc"]);
    return newListBlocksData;
  }, [headerState, country]);

  const positionSlug = headerState && headerState.slug;
  const isChecked = useMemo(
    () =>
      headerState?.enable
        ? !!(headerState && headerState.enable && headerState.enable === 1)
        : false,
    [headerState]
  );
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  resetServerContext();

  const handleChangeSwitch = (value: boolean) => {
    if (value) {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.POSITION,
        "Header_TurnOn",
        "Header",
        ACTION_CONFIG.TURN_ON
      );
    } else {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.POSITION,
        "Header_TurnOff",
        "Header",
        ACTION_CONFIG.TURN_OFF
      );
    }
    const enableValue = value ? 1 : 0;
    dispatch(
      disableEnablePosition({ enableValue, positionSlug: positionSlug })
    );
  };

  const isDropDisabled =
    (!addNewBlockButtonDrag && !headingDrag) ||
    (addNewBlockButtonDrag && listBlocksSortOrder?.length === 0);

  return (
    <>
      <Divider
        contentPosition="left"
        className={cls(
          "divider-design adm-default ",
          PRODUCT_TOUR_CLASS_NAME.STEP_6,
          isChecked ? "" : "hidden-divider"
        )}
      >
        <div className="flex justify-center items-center font-normal">
          {t("ss_builder_header_title")}{" "}
          <PositionInfo
            actionBtn={
              <div className="cursor-pointer">
                <InfomationPositionIcon
                  width={13}
                  height={13}
                  className="ml-1"
                />
              </div>
            }
            info={{
              image:
                "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-6.gif",
              title: t("ss_builder_header_introduction_title"),
              desctiption: t("ss_builder_header_introduction_description"),
            }}
          />
        </div>
        <div className="absolute top-0 right-[var(--block-outside-space)]">
          <Switch
            style={{
              "--height": "18px",
              "--width": "34px",
            }}
            checked={isChecked}
            onChange={handleChangeSwitch}
          />
        </div>
      </Divider>
      <div
        className={cls("position-wrapper", isChecked ? "" : "hide-position")}
      >
        <Droppable
          droppableId={
            positionSlug ? `designs-${positionSlug}` : "designs-header-default"
          }
          isDropDisabled={isDropDisabled}
        >
          {(provided, snapshot): JSX.Element => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="design-header-class relative"
            >
              {listBlocksSortOrder?.length > 0 ? (
                listBlocksSortOrder.map((block, index) => {
                  let isFirstItem = false;
                  if (
                    listBlocksSortOrder?.length === 1 ||
                    (index === 0 && block?.block_type !== BLOCK_TYPE.TAB_BAR)
                  ) {
                    isFirstItem = true;
                  } else if (
                    listBlocksSortOrder[0].block_type === BLOCK_TYPE.TAB_BAR &&
                    index === 1
                  ) {
                    isFirstItem = true;
                  }
                  return !BLOCK_NOT_DISPLAY.includes(block.block_type) ? (
                    <section
                      id={block.uid}
                      key={block.uid}
                      className={cls(
                        block.block_type === "tab_bar" &&
                          block?.style_attributes?.ghim_style ===
                            TAB_GHIM_STYLE.ALWAYS_GHIM &&
                          "hidden"
                      )}
                      style={{
                        display:
                          block.block_type === "tab_bar" &&
                          block?.style_attributes?.ghim_style ===
                            TAB_GHIM_STYLE.ALWAYS_GHIM
                            ? "none"
                            : "",
                      }}
                    >
                      <Draggable
                        draggableId={`designs-${block.uid}`}
                        index={index}
                        key={`designs-${block.uid}`}
                      >
                        {(provided, snapshot): JSX.Element => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={cls(
                              "block-cursor-default",
                              snapshot.isDragging &&
                                "adm-item-custom-design bg-drag-drop blur-none"
                            )}
                          >
                            <BlockDraggingSpace
                              addNewBlockButtonDrag={addNewBlockButtonDrag}
                            >
                              <MappingTypeComponent
                                blockData={block}
                                positionSlug={positionSlug}
                                index={index}
                                listBlocksSortOrder={listBlocksSortOrder}
                                isFirstItem={isFirstItem}
                              />
                            </BlockDraggingSpace>
                            {!addNewBlockButtonDrag && (
                              <Divider
                                style={{
                                  color: "#1890FF",
                                  borderColor: "#1890FF",
                                  borderStyle: "dashed",
                                  height: `${block_space}px`,
                                  margin: "0px",
                                  visibility:
                                    !(
                                      index !==
                                        listBlocksSortOrder.length - 1 &&
                                      block?.style_attributes?.ghim_style !==
                                        TAB_GHIM_STYLE.ALWAYS_GHIM
                                    ) ||
                                    headingDrag ||
                                    addNewBlockButtonDrag
                                      ? "hidden"
                                      : "unset",
                                  opacity: !isDropDisabled ? 0 : 1,
                                  paddingLeft: outside_space
                                    ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
                                    : "",
                                  paddingRight: outside_space
                                    ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
                                    : "",
                                }}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    </section>
                  ) : null;
                })
              ) : (
                <EmptyBlock data={{ position: "footer", uid: "" }} />
              )}
              {provided.placeholder}
              {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                <PlaceholderCustom
                  placeholderProps={placeholderProps}
                  isFullBlock={listBlocksSortOrder?.length >= 4}
                />
              )}
            </div>
          )}
        </Droppable>
        <div
          style={{
            height: `${block_space}px`,
            marginTop: `-${block_space}px`,
            marginBottom: `-${block_space}px`,
          }}
        />
      </div>
    </>
  );
};

export default HeaderDesign;
