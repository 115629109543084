import { KEY_TOKEN } from "@share/configs/system";
import { Dialog, SpinLoading } from "antd-mobile";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { ONBOARDING_PAGE } from "@share/configs/pages";
import {
  GG_FONT_URI,
  INITIAL_ONBOARDING,
  KEY_IS_LOGIN,
} from "@share/configs/const";
import { useRouter } from "next/router";
import Main from "@features/main/pages/main";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import React, { useEffect, useMemo } from "react";
import Logged from "@share/components/layouts/Logged";
import { ExclamationTriangleIcon } from "@share/icons";
import Head from "next/head";
import { COUNTRY_CONFIG } from "@share/configs/country-config";
import { sendRequestLogin } from "@share/api";
import { FINISH_ONBOARD_ENUM } from "@features/main/store/interface";
import { useGetFontValue } from "@share/hooks/useGetFontValue";
import { setFontLoading } from "@features/design/store/externalStateSlice";
import axios from "axios";

const baseApiUrl = process.env.NEXT_PUBLIC_API_URL;

const API_URL = {
  me: baseApiUrl + "/api/account/me",
};

const Pages = (props: { country: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();

  const creatorProfile = useAppSelector((state) => state.creatorProfile);
  const userId = creatorProfile?.user_id;
  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const finishOnboard = creatorProfile?.finish_onboard;

  const siteSettings = useAppSelector((state) => state.siteSettings);
  const paragraphSetting = siteSettings?.display_setting?.paragraph_setting;
  const titleSetting = siteSettings?.display_setting?.title_setting;

  const { fontValue } = useGetFontValue(paragraphSetting, titleSetting);

  const dataFont = useAppSelector((state) => state.externalState?.data_font);
  const fontWeightAvailable = useAppSelector(
    (state) => state.externalState.font_weight_available
  );
  const { title, paragraph } = dataFont || {};

  const previewFontParagraphSrc = useMemo(
    () =>
      paragraph
        ? `${GG_FONT_URI}?family=${paragraph.replace(
            /\s+/g,
            "+"
          )}:${fontWeightAvailable?.join(",")}`
        : null,
    [fontWeightAvailable, paragraph]
  );

  const previewFontTitleSrc = useMemo(
    () =>
      title
        ? `${GG_FONT_URI}?family=${title.replace(
            /\s+/g,
            "+"
          )}:${fontWeightAvailable?.join(",")}`
        : null,
    [fontWeightAvailable, title]
  );

  useEffect(() => {
    if (fontWeightAvailable) dispatch(setFontLoading(false));
  }, [fontWeightAvailable]);

  const isShowDesignFontLink = !previewFontParagraphSrc && !previewFontTitleSrc;
  useEffect(() => {
    if (
      finishOnboard !== FINISH_ONBOARD_ENUM.TRUE &&
      finishOnboard != INITIAL_ONBOARDING
    ) {
      router.push(ONBOARDING_PAGE.onboarding);
    }
  }, [finishOnboard]);

  const handleRequestLogin = async () => {
    const dataRequest = {
      user_id: userId,
    };
    try {
      await sendRequestLogin(dataRequest);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const isLogin = Cookies.get(KEY_IS_LOGIN);
    if (userId && isLogin != userId) {
      handleRequestLogin();
      const expiresDate = new Date(Date.now() + 10 * 60 * 1000);
      Cookies.set(KEY_IS_LOGIN, userId, { expires: expiresDate });
    }
  }, [userId]);

  useEffect(() => {
    const isError = dynamicConfig?.isError;
    if (isError) {
      Dialog.alert({
        header: (
          <ExclamationTriangleIcon
            width={48}
            height={48}
            fillColor="var(--adm-color-warning)"
          />
        ),
        confirmText: t("ss_builder_dialog_error_confirm_text"),
        title: t("ss_builder_dialog_error_title"),
        content: (
          <div className="text-center">
            {t("ss_builder_dialog_error_content")}
          </div>
        ),
        onConfirm: () => {
          window.location.reload();
        },
      });
    }
  }, [dynamicConfig]);

  const titlePage = `${t("ss_builder_title_page_builder")} - ${
    COUNTRY_CONFIG[props?.country] || COUNTRY_CONFIG.OTHER
  }`;

  return (
    <div>
      <Head>
        <title>{titlePage}</title>
        <meta
          name="description"
          content={t("ss_builder_description_page_builder")}
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="https://i.ecomobi.com/128x128/ssp/passio-page/passio.png"
        ></link>
        {/*import font for preview*/}
        {!!previewFontParagraphSrc && (
          <link
            href={previewFontParagraphSrc}
            rel="stylesheet"
          />
        )}
        {!!previewFontTitleSrc && (
          <link
            href={previewFontTitleSrc}
            rel="stylesheet"
          />
        )}

        {/*import link font*/}
        {isShowDesignFontLink && fontValue ? (
          <link
            href={`${GG_FONT_URI}?family=${fontValue}`}
            rel="stylesheet"
          />
        ) : null}

        <meta
          key={"meta_viewport"}
          name="viewport"
          content="minimum-scale=1, initial-scale=1, user-scalable=0, width=device-width, shrink-to-fit=no, maximum-scale=1.0, viewport-fit=cover"
        />
      </Head>
      {finishOnboard !== FINISH_ONBOARD_ENUM.TRUE ? (
        <>
          <div className="bg-white h-screen w-screen flex flex-wrap justify-center items-center content-center gap-3">
            <SpinLoading
              color="primary"
              style={{ "--size": "32px" }}
            />
          </div>
          <Logged>
            <></>
          </Logged>
        </>
      ) : (
        <Main />
      )}
    </div>
  );
};

export default Pages;

export async function getServerSideProps(context) {
  const token = context?.req?.cookies[KEY_TOKEN];
  let response = null;
  try {
    response = await axios.get(API_URL.me, {
      headers: {
        method: "GET",
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.log("error", error);
  }
  let country = COUNTRY_CONFIG.OTHER;
  if (response?.data?.data?.country) {
    country = response?.data?.data?.country;
  }
  return {
    props: {
      country,
    },
  };
}
