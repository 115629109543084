import {
  CheckCircleIcon,
  InformationIcon,
  SearchIcon,
  StoreIcon,
  SwatchBookIcon,
} from "@share/icons";
import React, { useEffect, useMemo, useState } from "react";
import ThemeTemplateLists, {
  themeTemplateInterface,
} from "../ThemeTemplateLists";
import { useTranslation } from "react-i18next";
import { BackArrow } from "@features/setting/components/Icons";
import {
  TAB_BAR_INDEX,
  THEME_PREVIEW_KEY,
  THEME_RENDER_LIST_KEY,
  THEME_TEMPLATE_KEY,
} from "@share/configs/const";
import ThemeTemplatePreview from "../ThemeTemplatePreview";
import FullScreenPopup from "@share/components/full-screen-popup";
import ThemeTemplateSaving from "../ThemeTemplateSaving";
import { Modal, SearchBar, Toast } from "antd-mobile";
import {
  getPackageSass,
  requestApplyThemeTemplate,
  requestDeleteThemeTemplate,
  requestSearchThemeTemplate,
  requestUpdateThemeTemplate,
  requestUploadImage,
} from "@share/api";
import cloneDeep from "lodash/cloneDeep";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { setSiteInfo } from "@features/main/store/siteSettingSlice";
import { setPosition } from "@features/main/store/positionsSlice";
import { CREATOR_ROLE_ENUM } from "@features/main/store/interface";
import gaEvents, {
  ACTION_CONFIG,
  BLOCK_TYPE_EVENT_CONFIG,
  CATEGORY_CONFIG,
  submitEventSearch,
  LABEL_CONFIG,
} from "@share/lib/ga-events";
import ThemeTourOpening from "@features/design/components/ThemeTemplateHandler/ThemeTourOpening";
import update from "immutability-helper";
import {useSelector} from "react-redux";
import {selectProfile} from "@features/user/userSlice";

const ThemeTemplateStore = (props) => {
  const {
    handleRedirectTabs,
    onCancelThemeTemplateStore,
    siteSettings,
    user,
    activeIndex,
  } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const siteSettingUserRootId = siteSettings?.id;
  const userRootId = siteSettings?.user_id;
  const [isOpenThemeTemplatePreview, setIsOpenThemeTemplatePreview] =
    useState(false);
  const [isOpenEditThemeSaving, setIsOpenEditThemeSaving] = useState(false);
  const [themeTemplateArr, setThemeTemplateArr] = useState<
    Array<themeTemplateInterface>
  >([]);
  const [searchedTemplates, setSearchedTemplate] = useState<
    Array<themeTemplateInterface>
  >([]);
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const [themeSelectData, setThemeSelectData] = useState(null);
  const [value, setValue] = useState("");

  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);
  const creator_role = passioPageProfile?.creator_role;
  const isAdminRole = useMemo(
    () => creator_role === CREATOR_ROLE_ENUM.ADMIN,
    [creator_role]
  );

  const userState = useAppSelector((state) => state.user);
  const packageId = userState && userState.kol && userState.kol.package_type;

  useEffect(() => {
    const requestThemeTemplateList = async () => {
      let listThemeResponse = null;
      let packageSASS = null;
      const dataRequest = {
        per_page: 100,
        keyword: "",
        is_admin: isAdminRole,
      };
      try {
        listThemeResponse = await requestSearchThemeTemplate(dataRequest);
        packageSASS = await getPackageSass();
      } catch (error) {
        console.log(error);
      }

      const listThemePremium = packageSASS?.data?.data?.packages?.find(
        (item) => item.id === 1
      )?.params?.passio_page_service?.premium_theme;
      const listThemeArr = listThemeResponse?.data?.map((theme) => {
        if (listThemePremium?.includes(theme?.id))
          return {
            ...theme,
            is_premium: true,
          };
        return { ...theme, is_premium: false };
      });
      // check thi truong PH
      if (listThemeArr && (user?.kol?.country === "PH" || isAdminRole )) {
        setThemeTemplateArr(listThemeArr);
      }
      if (listThemeArr && user?.kol?.country !== "PH" && !isAdminRole) {
        const listThemeArrClone = listThemeArr?.filter(
          (item) => !item?.is_premium
        );
        setThemeTemplateArr(listThemeArrClone);
      }
    };
    requestThemeTemplateList();
  }, [activeIndex]);

  const handleSelectTheme = (val) => {
    if (val) {
      setThemeSelectData(val);
      setIsOpenThemeTemplatePreview(true);
    }
  };
  const handleEditTheme = (val) => {
    if (val) {
      setThemeSelectData(val);
      setIsOpenEditThemeSaving(true);
    }
  };

  const handleDeleteTheme = async (themeId) => {
    let responseDeleteTheme = null;
    try {
      responseDeleteTheme = await requestDeleteThemeTemplate(themeId);
    } catch (error) {
      console.log(error);
    }
    if (
      /^20[0-9]$/.test(responseDeleteTheme?.status.toString()) &&
      responseDeleteTheme?.data?.success
    ) {
      const themeTemplateArrClone = cloneDeep(themeTemplateArr);
      const newThemeTemplateArr = themeTemplateArrClone?.filter(
        (ele) => ele.id !== themeId
      );
      setThemeTemplateArr(newThemeTemplateArr);
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_delete_theme_template_successfully_label")}
          </div>
        ),
      });
    }
  };

  const handleShowHideTheme = async (themeId) => {
    let response = null;
    const themeSelected = themeTemplateArr.find((item) => item.id === themeId);
    const { status } = themeSelected;
    const dataRequest = {
      user_id: siteSettings.user_id,
      site_setting_id: siteSettings.id,
      title: themeSelected.title,
      image: themeSelected.image,
      id: themeId,
      status: status === 1 ? 2 : 1,
    };
    try {
      response = await requestUpdateThemeTemplate(dataRequest);
    } catch (error) {
      console.log(error);
    }
    if (/^20[0-9]$/.test(response?.status.toString()) && response?.data) {
      const themeTemplateArrClone = cloneDeep(themeTemplateArr);
      const newThemeTemplateArr = themeTemplateArrClone?.map((ele) => {
        if (ele.id === themeId) {
          return { ...ele, status: status === 1 ? 2 : 1 };
        }
        return ele;
      });
      setThemeTemplateArr(newThemeTemplateArr);
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block w-[30px] h-[30px]"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {status === 1
              ? t("ss_builder_disable_theme_successfully_label")
              : t("ss_builder_enable_theme_successfully_label")}
          </div>
        ),
      });
    }
  };

  const handleConfirmThemeTemplatePreview = async () => {
    let reponseApplyTheme = null;
    const dataRequest = {
      user_id: siteSettings.user_id,
      template_id: themeSelectData?.id,
    };
    try {
      reponseApplyTheme = await requestApplyThemeTemplate(dataRequest);
    } catch (error) {
      console.log(error);
      const statusCode = error?.response?.status;
      if (statusCode == 404) {
        Modal.show({
          header: (
            <InformationIcon
              className="w-11 h-11"
              fillColor="#FF8F1F"
              onClick={() => {}}
            />
          ),
          content: (
            <div className="flex flex-col text-center items-center">
              <div className="text-lg font-bold leading-[25px]">
                {t("ss_builder_dont_apply_theme_content_modal_label")}
              </div>
              <div className="">
                {t("ss_builder_dont_apply_theme_content_modal_description")}
              </div>
            </div>
          ),
          className: "modal-error-class",
          closeOnAction: true,
          actions: [
            {
              key: "confirm",
              text: t(
                "ss_builder_dont_apply_theme_content_modal_btn_confirm_label"
              ),
            },
          ],
        });
      }
    }
    if (/^20[0-9]$/.test(reponseApplyTheme?.status.toString())) {
      dispatch(setSiteInfo({ sites: reponseApplyTheme?.data?.data }));
      if (reponseApplyTheme?.data?.data?.positions) {
        dispatch(
          setPosition({ positions: reponseApplyTheme?.data?.data?.positions })
        );
      }
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_apply_theme_template_successfully_label")}
          </div>
        ),
      });
      onCancelThemeTemplateStore && onCancelThemeTemplateStore();
      handleRedirectTabs && handleRedirectTabs();
      setIsOpenThemeTemplatePreview(false);
    }
  };

  const handleRequestUpdateTheme = async (title, image, id, tags = []) => {
    let reponseSaveTheme = null;
    const dataRequest = {
      user_id: siteSettings.user_id,
      site_setting_id: siteSettings.id,
      title: title,
      image: image,
      id: id,
      tags: tags ?? [],
    };
    try {
      reponseSaveTheme = await requestUpdateThemeTemplate(dataRequest);
    } catch (error) {
      console.log(error);
    }
    if (/^20[0-9]$/.test(reponseSaveTheme?.status.toString())) {
      const themeTemplateArrClone = cloneDeep(themeTemplateArr);
      const newThemeTemplateArr = themeTemplateArrClone?.map((ele) => {
        return ele.id === reponseSaveTheme?.data?.id
          ? reponseSaveTheme?.data
          : ele;
      });
      setThemeTemplateArr(newThemeTemplateArr);
      Toast.show({
        icon: (
          <CheckCircleIcon
            className="text-center inline-block"
            fillColor="#00B578"
          />
        ),
        content: (
          <div className="text-center">
            {t("ss_builder_edit_theme_template_successfully_label")}
          </div>
        ),
      });
      setIsOpenEditThemeSaving(false);
    }
  };

  const handleConfirmEditTheme = async (data) => {
    const { image, image_name, title, id, tags = [] } = data;
    let responseImage = null;
    if (!image?.startsWith("http")) {
      const dataResquestUploadImage = {
        id: siteSettings.id,
        user_id: siteSettings.user_id,
        name: image_name,
        content: image,
      };
      try {
        responseImage = await requestUploadImage(dataResquestUploadImage);
      } catch (error) {
        console.log(error);
      }
      if (responseImage) {
        const imageUrl = responseImage.url;
        handleRequestUpdateTheme(title, imageUrl, id, tags);
      }
    } else {
      handleRequestUpdateTheme(title, image, id, tags);
    }
  };

  const themeEditData = useMemo(
    () => themeTemplateArr?.find((x) => x.id === themeSelectData?.id),
    [themeTemplateArr, themeSelectData]
  );

  const handleSearchThemeTemplate = (value) => {
    const searchKeys = searchStringTransformed(value);
    const allTheme = update([], { $push: themeTemplateArr });

    if (searchKeys.length > 0) {
      gaEvents.submitEventSearch(
        window.location.origin,
        "themeStore",
        value
      );
      const searchedThemes = [];
      allTheme.forEach((theme) => {
        if (isThemeMatchSearchKey(theme, searchKeys)) {
          searchedThemes.push(theme);
        }
      });
      setSearchedTemplate(searchedThemes);
      setIsSearchMode(true);
    } else {
      setSearchedTemplate(themeTemplateArr);
      setIsSearchMode(false);
    }
  };

  const handleClearSearch = () => {
    gaEvents.submitEventV2(
      passioPageProfile,
      CATEGORY_CONFIG.THEME_TEMPLATE,
      BLOCK_TYPE_EVENT_CONFIG.THEME_TEMPLATE_SEARCH_CLEAR,
      LABEL_CONFIG.CLEAR_SEARCH,
      ACTION_CONFIG.SEARCH
    );
  };

  const numberThemeTemplates = searchedTemplates.length;

  const isThemeMatchSearchKey = (
    theme: themeTemplateInterface,
    searchKey: Array<string>
  ) => {
    const tagsAndTitleArr = getTagsAndTitleList(theme);
    const isMatch = tagsAndTitleArr.some((tag) => {
      const splitedTagsArr = searchStringTransformed(tag);
      const isTagIncludeAllKey = searchKey.every((key) =>
        splitedTagsArr.includes(key)
      );
      return isTagIncludeAllKey;
    });
    return isMatch;
  };

  const searchStringTransformed = (str: string): Array<string> =>
    str
      .toLowerCase()
      .split(/[ ,]+/)
      .filter((e) => !!e);

  const getTagsAndTitleList = (
    theme: themeTemplateInterface
  ): Array<string> => {
    const tags = update([], { $push: theme?.tags || [] });
    const name = [theme.title];
    return update(tags, { $push: name });
  };

  return (
    <div
      className="max-width-content-class m-auto h-full bg-white relative"
      // style={{
      //   height: "calc(100vh - 60px)",
      //   overflow: "auto",
      // }}
    >
      <div
        className="max-width-content-class fixed top-0 w-full bg-white pb-3 z-[10]"
        style={{
          borderBottom: "1px solid #EEEEEE",
        }}
      >
        <div className="text-center py-3 relative">
          {activeIndex !== TAB_BAR_INDEX.TEMPLATE && (
            <span
              onClick={() => onCancelThemeTemplateStore()}
              className="absolute left-3 cursor-pointer"
            >
              <BackArrow />
            </span>
          )}
          <div className="items-center flex justify-center gap-1">
            <StoreIcon className="inline-block w-[22px] h-[22px]" />
            <span className="text-[18px] leading-6">
              {t("ss_builder_theme_template_store_title")}
            </span>
            <div className="absolute top-[50%] right-[1rem] translate-y-[-50%]">
              <ThemeTourOpening />
            </div>
          </div>
        </div>
        {/*<SearchBar*/}
        {/*  placeholder={t("ss_builder_theme_template_search_bar_placeholder")}*/}
        {/*  className="mx-4"*/}
        {/*  onChange={(val) => handleSearchThemeTemplate(val)}*/}
        {/*  onSearch={(val) => handleRequestSearchTheme(val)}*/}
        {/*/>*/}

        <div className="flex w-full bg-white search-block h-[36px] px-4 theme-store-tour-step-1">
          <SearchBar
            icon={() => null}
            placeholder={t("ss_builder_theme_template_search_bar_placeholder")}
            onSearch={handleSearchThemeTemplate}
            onChange={(val) => {
              setValue(val);
            }}
            style={{
              width: "100%",
            }}
            onClear={() => handleClearSearch()}
          />
          <div
            className="bg-[#EE3244] flex h-43 w-43 cursor-pointer"
            style={{
              width: "43px",
              height: "36px",
              borderRadius: "0 6px 6px 0",
            }}
            onClick={() => {
              handleSearchThemeTemplate(value);
              gaEvents.submitEventSearch(
                window.location.origin,
                "themeStore",
                value
              );
            }}
          >
            <SearchIcon className="m-auto" />
          </div>
        </div>
      </div>

      <div className="bg-white px-4 pt-[6.5rem]">
        {isSearchMode ? (
          <>
            <div className="text-lg leading-6">
              {t("ss_builder_search_results_theme_store")}
            </div>
          </>
        ) : (
          <div>
            <div className="text-lg leading-6">
              {t("ss_builder_theme_template_store_instruction_title")}
            </div>
            <div className="text-[13px] leading-[17px] text-[#737373]">
              {t("ss_builder_theme_template_store_instruction_subtitle")}
            </div>
          </div>
        )}
        {numberThemeTemplates > 0 && isSearchMode && (
          <div>
            <div className="text-[13px] leading-[17px] text-[#737373]">
              <span>
                {t("ss_builder_theme_template_theme_store", {
                  num_results: numberThemeTemplates,
                })}
              </span>
            </div>
          </div>
        )}
      </div>

      {numberThemeTemplates === 0 && isSearchMode ? (
        <div className="px-4 pt-4 pb-[65px] bg-white text-center">
          <div className="flex justify-center items-center mb-1">
            <SwatchBookIcon
              width={32}
              height={32}
            />
          </div>
          <div className="text-lg leading-6">
            {t("ss_builder_no_themes_found_theme_store")}
          </div>
          <div className="text-[13px] leading-[17px] text-[#737373] mt-1">
            {t("ss_builder_try_another_keyword_theme_store")}
          </div>
        </div>
      ) : (
        <ThemeTemplateLists
          onSelectTheme={handleSelectTheme}
          onEditTheme={handleEditTheme}
          onDeleteTheme={handleDeleteTheme}
          KEY={THEME_RENDER_LIST_KEY.THEME_STORE}
          themeTemplateData={
            isSearchMode ? searchedTemplates : themeTemplateArr
          }
          onShowHideTheme={handleShowHideTheme}
        />
      )}
      {isOpenThemeTemplatePreview && (
        <FullScreenPopup
          visible={isOpenThemeTemplatePreview}
          onMaskClick={() => setIsOpenThemeTemplatePreview(false)}
          isLimitWidth={false}
          style={{ background: "#fff" }}
        >
          <ThemeTemplatePreview
            onCancelThemeTemplatePreview={() =>
              setIsOpenThemeTemplatePreview(false)
            }
            onConfirmThemeTemplatePreview={handleConfirmThemeTemplatePreview}
            user={user}
            selectedThemeData={themeSelectData}
            KEY={THEME_PREVIEW_KEY.PREVIEW_AND_APPLY}
            siteSettingUserRootId={siteSettingUserRootId}
            userRootId={userRootId}
            isViewTheme={true}
          />
        </FullScreenPopup>
      )}
      {isOpenEditThemeSaving && (
        <FullScreenPopup
          visible={isOpenEditThemeSaving}
          onMaskClick={() => {
            setIsOpenEditThemeSaving(false);
          }}
        >
          <ThemeTemplateSaving
            handleCancelThemeSaving={() => setIsOpenEditThemeSaving(false)}
            KEY={THEME_TEMPLATE_KEY.EDIT}
            handleConfirmThemeSaving={handleConfirmEditTheme}
            themeTemplateData={themeEditData}
          />
        </FullScreenPopup>
      )}
    </div>
  );
};

export default ThemeTemplateStore;
