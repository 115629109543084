import React, { useMemo, useState } from "react";
import {
  DragDropContext,
  DropResult,
  resetServerContext,
} from "react-beautiful-dnd";

import BodyDesign from "./BodyDesign";
import ExpandDesign from "./ExpandDesign";
import FooterDesign from "./FooterDesign";
import HeaderDesign from "./HeaderDesign";
import AddNewButtonDragging, {
  ADD_NEW_BUTTON_DRAGGABLE_ID,
} from "./AddNewButtonDragging";
import { handleCapitalizeFirstLetter } from "@share/lib";
import gaEvents, {
  ACTION_CONFIG,
  BLOCK_TYPE_EVENT_CONFIG,
  CATEGORY_CONFIG,
} from "@share/lib/ga-events";
import orderBy from "lodash/orderBy";
import { POSITION_DROPPABLE_ID } from "@share/configs/const";
import { useAppDispatch, useAppSelector } from "@app/hooks";
import { BLOCK_TYPE } from "@features/block/block.conf";
import {
  getBlocksBySlug,
  sortBlock,
} from "@features/main/store/positionsSlice";
import { POSITION_SLUG } from "@features/main/store/interface";
import { BLOCK_TYPE_BY_GROUP } from "@features/design/design.config";
import { POSITION_TYPE } from "@features/block/config/interface";

const DesignMainLayout = (props) => {
  const {
    headerState,
    bodyState,
    isEnableBody,
    footerState,
    expandPosition,
    addNewBlockStyle,
    handleOpenPositionSelectedList,
    showFirstGuide,
    onUpdatePositionWhenDragBtn,
  } = props;

  const [headingDrag, setHeadingDrag] = useState(false);
  const [bodyDrag, setBodyDrag] = useState(false);
  const [footerDrag, setFooterDrag] = useState(false);
  const [expandPopupDrag, setExpandPopupDrag] = useState(false);
  const [expandFloatingIconsDrag, setExpandFloatingIconsDrag] = useState(false);
  const [addNewBlockButtonDrag, setAddNewBlockButtonDrag] = useState(false);
  const [placeholderProps, setPlaceholderProps] = useState({});
  const [
    addNewBlockButtonDragBeforeCapture,
    setAddNewBlockButtonDragBeforeCapture,
  ] = useState(false);
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const queryAttr = "data-rbd-droppable-id";

  const dispatch = useAppDispatch();

  const imgPopupBlocks = useAppSelector(
    getBlocksBySlug(POSITION_TYPE.EXPAND, BLOCK_TYPE_BY_GROUP.POPUP)
  );
  const floatingBlocks = useAppSelector(
    getBlocksBySlug(POSITION_TYPE.EXPAND, BLOCK_TYPE_BY_GROUP.FLOATING)
  );

  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const activeKeyTab =
    dynamicConfig && dynamicConfig.tabs && dynamicConfig.tabs.activeKeyTab;

  const listTabBody = bodyState?.block_list?.find(
    (item) => item.block_type == BLOCK_TYPE.TAB
  )?.content_attributes?.tabs;

  const listTabBodySortOrder =
    listTabBody && orderBy(listTabBody, ["sort_order"], ["asc"]);

  const activeKey = useMemo(() => {
    const isExistActiveTab = listTabBodySortOrder?.find(
      (x) => x?.uid === activeKeyTab
    );
    if (activeKeyTab && isExistActiveTab) {
      return activeKeyTab;
    } else {
      const tabDefault =
        listTabBodySortOrder &&
        listTabBodySortOrder.find((x) => x.isDefault === true);
      const activeKeyDefault = tabDefault && tabDefault.uid;
      return activeKeyDefault;
    }
  }, [activeKeyTab, listTabBodySortOrder]);

  const tabBodyActive =
    listTabBodySortOrder && activeKey
      ? listTabBodySortOrder.find((x) => x.uid === activeKey)
      : null;

  const listBlockBody = tabBodyActive
    ? tabBodyActive.block_list
    : listTabBodySortOrder[0].block_list;

  const listBlocksSortOrderBody =
    listBlockBody && orderBy(listBlockBody, ["sort_order"], ["asc"]);

  resetServerContext();

  const getBlockListsOfPosition = (positionData) => {
    const listBlocksData = positionData?.block_list;
    const newListBlocksData =
      listBlocksData && orderBy(listBlocksData, ["sort_order"], ["asc"]);
    return newListBlocksData;
  };

  const reorder = (blockLists: any, startIndex: number, endIndex: number) => {
    const result = Array.from(blockLists);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const orderedBlockList = result.map((ele, idx) => ({
      ...(ele as Object),
      sort_order: idx + 1,
    }));
    return orderedBlockList;
  };

  const onDragEnd = (result: DropResult): void => {
    setPlaceholderProps({});
    headingDrag && setHeadingDrag(false);
    bodyDrag && setBodyDrag(false);
    footerDrag && setFooterDrag(false);
    expandPopupDrag && setExpandPopupDrag(false);
    expandFloatingIconsDrag && setExpandFloatingIconsDrag(false);
    addNewBlockButtonDrag && setAddNewBlockButtonDrag(false);
    addNewBlockButtonDragBeforeCapture &&
      setAddNewBlockButtonDragBeforeCapture(false);
    let blockType = null;
    let itemsOrder = null;
    let positionSlug = null;
    let tabUid = null;
    let gaLabel = null;
    const { index: startIndex, droppableId: startDroppableId } =
      result?.source || {};
    const { index: endIndex, droppableId: endDroppableId } =
      result?.destination || {};
    const blockListsHeader = getBlockListsOfPosition(headerState);
    const blockListsFooter = getBlockListsOfPosition(footerState);
    const isFullBlock =
      (endDroppableId === POSITION_DROPPABLE_ID.HEADER &&
        blockListsHeader.length >= 4) ||
      (endDroppableId === POSITION_DROPPABLE_ID.FOOTER &&
        blockListsFooter?.length >= 4) ||
      (endDroppableId === POSITION_DROPPABLE_ID.BODY &&
        listBlocksSortOrderBody?.length >= 10);
    if (
      !result.destination ||
      startIndex === endIndex ||
      (addNewBlockButtonDrag && isFullBlock)
    ) {
      return;
    }
    switch (endDroppableId) {
      case POSITION_DROPPABLE_ID.HEADER:
        blockType = blockListsHeader?.find(
          (x, idx) => idx === startIndex
        )?.block_type;
        itemsOrder = reorder(blockListsHeader, startIndex, endIndex);
        positionSlug = POSITION_SLUG.HEADER;
        gaLabel = "Header";
        break;
      case POSITION_DROPPABLE_ID.BODY:
        const indexTab =
          listTabBodySortOrder.findIndex((item) => item?.uid === activeKey) + 1;
        blockType = listBlocksSortOrderBody?.find(
          (x, idx) => idx === startIndex
        )?.block_type;
        itemsOrder = reorder(listBlocksSortOrderBody, startIndex, endIndex);
        positionSlug = POSITION_SLUG.BODY;
        tabUid = activeKey;
        gaLabel = `Body - Tab ${indexTab}`;
        break;
      case POSITION_DROPPABLE_ID.FOOTER:
        blockType = blockListsFooter?.find(
          (x, idx) => idx === startIndex
        )?.block_type;
        itemsOrder = reorder(blockListsFooter, startIndex, endIndex);
        positionSlug = POSITION_SLUG.FOOTER;
        gaLabel = "Footer";
        break;
      case POSITION_DROPPABLE_ID.EXPAND_FLOATING_ICONS:
        itemsOrder = imgPopupBlocks
          .concat(reorder(floatingBlocks, startIndex, endIndex))
          .map((e, index) => ({
            ...e,
            sort_order: index + 1,
          }));
        positionSlug = POSITION_SLUG.EXPAND;
        gaLabel = "Extended - Floating Icons";
        break;
      case POSITION_DROPPABLE_ID.EXPAND_POPUP:
        itemsOrder = imgPopupBlocks
          .concat(reorder(floatingBlocks, startIndex, endIndex))
          .map((e, index) => ({
            ...e,
            sort_order: index + 1,
          }));
        positionSlug = POSITION_SLUG.EXPAND;
        gaLabel = "Extended - Popup";
        break;
    }
    if (
      startDroppableId === POSITION_DROPPABLE_ID.ADD_NEW_BLOCK_BUTTON_DRAGGING
    ) {
      onUpdatePositionWhenDragBtn({
        position: positionSlug,
        uid: tabUid,
        sort_order: endIndex + 1,
      });
      if (gaLabel) {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.ADD_BLOCK_BUTTON,
          "AddBlockButton_DragDrop",
          gaLabel,
          ACTION_CONFIG.DRAG_AND_DROP
        );
      }
      return;
    }
    if (blockType) {
      const blockTypeEvent =
        BLOCK_TYPE_EVENT_CONFIG[blockType?.toUpperCase()] ||
        handleCapitalizeFirstLetter(blockType);
      if (startIndex > endIndex) {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.BLOCK,
          `Block_DragDropUp_${blockTypeEvent}`,
          blockTypeEvent,
          ACTION_CONFIG.DRAG_DROP_UP
        );
      } else {
        gaEvents.submitEventV2(
          passioPageProfile,
          CATEGORY_CONFIG.BLOCK,
          `Block_DragDropDown_${blockTypeEvent}`,
          blockTypeEvent,
          ACTION_CONFIG.DRAG_DROP_DOWN
        );
      }
    }
    if (itemsOrder && positionSlug) {
      dispatch(sortBlock({ itemsOrder, positionSlug, tabUid }));
    }
  };

  const onDragStart = (result: DropResult): void => {
    const startDroppableId = result?.source?.droppableId;
    switch (startDroppableId) {
      case POSITION_DROPPABLE_ID.HEADER:
        setHeadingDrag(true);
        break;
      case POSITION_DROPPABLE_ID.BODY:
        setBodyDrag(true);
        break;
      case POSITION_DROPPABLE_ID.FOOTER:
        setFooterDrag(true);
        break;
      case POSITION_DROPPABLE_ID.EXPAND_POPUP:
        setExpandPopupDrag(true);
        break;
      case POSITION_DROPPABLE_ID.EXPAND_FLOATING_ICONS:
        setExpandFloatingIconsDrag(true);
        break;
      case POSITION_DROPPABLE_ID.ADD_NEW_BLOCK_BUTTON_DRAGGING:
        setAddNewBlockButtonDrag(true);
        break;
    }
  };

  const onDragUpdate = (event: DropResult): void => {
    // console.log("event", event);
    if (!event.destination) {
      return;
    }
    const clientHeight = 48;
    let clientWidth = 0;
    let clientY = 0;
    const clientX = 0;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;
    const draggableId = event.draggableId;
    if (draggableId === ADD_NEW_BUTTON_DRAGGABLE_ID) {
      const droppableDOM = getDroppableDom(event.destination.droppableId);
      if (!droppableDOM) {
        return;
      }
      clientWidth = droppableDOM.clientWidth;
      const childrenArray = [...droppableDOM.children];
      clientY =
        parseFloat(window.getComputedStyle(droppableDOM).paddingTop) +
        childrenArray.slice(0, destinationIndex).reduce((total, curr) => {
          const style = curr?.currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

      setPlaceholderProps({
        clientHeight,
        clientWidth,
        clientY,
        clientX,
      });
    }
  };

  const onBeforeCapture = (event: DropResult): void => {
    if (event.draggableId === ADD_NEW_BUTTON_DRAGGABLE_ID) {
      setAddNewBlockButtonDragBeforeCapture(true);
    }
  };

  const getDroppableDom = (dropId) => {
    const domQuery = `[${queryAttr}='${dropId}']`;
    const element = document.querySelector(domQuery) as any;
    return element;
  };

  const isHideButtonAddBlock = useMemo(
    () =>
      headingDrag ||
      bodyDrag ||
      footerDrag ||
      expandFloatingIconsDrag ||
      expandPopupDrag,
    [
      headingDrag,
      bodyDrag,
      footerDrag,
      expandFloatingIconsDrag,
      expandPopupDrag,
    ]
  );

  return (
    <>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}
        onBeforeCapture={onBeforeCapture}
      >
        <header
          className={
            expandFloatingIconsDrag || expandPopupDrag || bodyDrag || footerDrag
              ? "inactive-position"
              : ""
          }
        >
          <HeaderDesign
            headerState={headerState}
            headingDrag={headingDrag}
            addNewBlockButtonDrag={addNewBlockButtonDrag}
            placeholderProps={placeholderProps}
          />
        </header>
        <main
          className={
            expandFloatingIconsDrag ||
            expandPopupDrag ||
            headingDrag ||
            footerDrag
              ? "inactive-position"
              : ""
          }
        >
          {isEnableBody ? (
            <BodyDesign
              bodyState={bodyState}
              bodyDrag={bodyDrag}
              addNewBlockButtonDrag={addNewBlockButtonDrag}
              placeholderProps={placeholderProps}
            />
          ) : (
            ""
          )}
        </main>
        <footer
          className={
            expandFloatingIconsDrag ||
            expandPopupDrag ||
            bodyDrag ||
            headingDrag
              ? "inactive-position"
              : ""
          }
        >
          <FooterDesign
            footerState={footerState}
            footerDrag={footerDrag}
            addNewBlockButtonDrag={addNewBlockButtonDrag}
            placeholderProps={placeholderProps}
          />
        </footer>
        <div
          className={
            footerDrag || bodyDrag || headingDrag ? "inactive-position" : ""
          }
        >
          <ExpandDesign
            position={expandPosition}
            expandPopupDrag={expandPopupDrag}
            expandFloatingIconsDrag={expandFloatingIconsDrag}
            addNewBlockButtonDrag={addNewBlockButtonDrag}
            placeholderProps={placeholderProps}
          />
          {/* <CopyRight /> */}
        </div>
        <section>
          <AddNewButtonDragging
            handleOpenPositionSelectedList={handleOpenPositionSelectedList}
            showFirstGuide={showFirstGuide}
            addNewBlockStyle={addNewBlockStyle}
            isHideButtonAddBlock={isHideButtonAddBlock}
            addNewBlockButtonDragBeforeCapture={
              addNewBlockButtonDragBeforeCapture
            }
          />
        </section>
      </DragDropContext>
    </>
  );
};

export default DesignMainLayout;
