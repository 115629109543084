import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { placeHolderImageIcon } from "@share/icons/product-tour-icon";
import { steps, STEP_INDEX } from "./setting-tour-config";
import ProductTourComponent from "@share/components/ProductTourComponent";
import { setDynamicParams } from "@features/main/store/dynamicSlice";
import { useAppDispatch } from "@app/hooks";

const SettingTour = (props) => {
  const { handleHideSettingTour } = props;
  const { t } = useTranslation();
  const productTourRef = useRef(null);
  const setRef = (ref) => {
    productTourRef.current = ref;
  };
  const dispatch = useAppDispatch();
  const stepsDefault = steps.map((ele) => ({
    title:
      `<div class="wrapper-image">` +
      placeHolderImageIcon +
      `
        <img class="img-product-tour" src=${ele.image_header} /> </div>
        <div class="title">${t(ele.title_key)}<div/>`,
    element: ele.using_selector
      ? document.querySelector(`.${ele.element_class}`)
      : `.${ele.element_class}`,
    intro: `<div> ${t(ele.intro_key)}<div/>`,
    tooltipClass: `tooltip-product-tour ${ele.tooltip_class}`,
    position: ele?.position || "auto",
  }));

  const onExit = () => {
    handleHideSettingTour();
  };

  const onBeforeExit = (stepIndex) => {};

  const onChangeProductTour = async (nextStepIndex, nextElement) => {
    console.log("222");
    dispatch(
      setDynamicParams({
        data: { isMarginSettingTour: nextStepIndex === STEP_INDEX.STEP_2 },
      })
    );
    if (nextStepIndex === STEP_INDEX.STEP_2) {
      window.scrollTo(0, document.body.scrollHeight);
    } else {
      window.scrollTo({ top: 0 });
    }
  };

  const onCompleteProductTour = () => {};

  const onBeforeChange = async (nextStepIndex, nextElement) => {};

  return (
    <ProductTourComponent
      steps={stepsDefault}
      onExit={onExit}
      onBeforeExit={onBeforeExit}
      onChange={onChangeProductTour}
      onComplete={onCompleteProductTour}
      onBeforeChange={onBeforeChange}
      setRef={setRef}
    />
  );
};

export default SettingTour;
