import { useAppSelector } from "@app/hooks";
import {
  BLOCK_NOT_DISPLAY,
  BLOCK_SETTING_DEFAULT_VALUE,
  COUNTRY_TH,
} from "@share/configs/const";
import { Divider } from "antd-mobile";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import React, { useMemo } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import MappingTypeComponent from "../../MappingTypeComponent";
import cls from "classnames";
import { useTranslation } from "react-i18next";
import { PRODUCT_TOUR_CLASS_NAME } from "../../ProductTour/product-tour-config";
import PositionInfo from "@features/design/components/PositionInfo";
import { InfomationPositionIcon } from "@share/icons";
import { BLOCK_TYPE } from "@features/block/block.conf";
import EmptyBlock from "@features/design/components/EmptyBlock";
import PlaceholderCustom from "../PlaceholderCustom";
import BlockDraggingSpace from "../BlockDraggingSpace";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import { COUNTRY_TH_BLOCK_HIDDEN } from "@features/block/config/block-selection";

const BodyDesign = (props) => {
  const { bodyState, bodyDrag, addNewBlockButtonDrag, placeholderProps } =
    props;
  const { t } = useTranslation();

  const dynamicConfig = useAppSelector((state) => state.dynamicConfig);
  const block_style = useAppSelector(
    (state) => state.siteSettings?.display_setting?.block_setting?.block_style
  );
  const country = useSelector((state: RootState) => state?.user?.kol?.country);
  const {
    block_space = BLOCK_SETTING_DEFAULT_VALUE.BLOCK_SPACE,
    outside_space,
  } = block_style ?? {};

  const activeKeyTab =
    dynamicConfig && dynamicConfig.tabs && dynamicConfig.tabs.activeKeyTab;

  const listTabBody = bodyState?.block_list?.find(
    (item) => item.block_type == BLOCK_TYPE.TAB
  )?.content_attributes?.tabs;

  const listTabBodySortOrder =
    listTabBody && orderBy(listTabBody, ["sort_order"], ["asc"]);

  const activeKey = useMemo(() => {
    const isExistActiveTab = listTabBodySortOrder?.find(
      (x) => x?.uid === activeKeyTab
    );
    if (activeKeyTab && isExistActiveTab) {
      return activeKeyTab;
    } else {
      const tabDefault = listTabBodySortOrder?.find(
        (x) => x.isDefault === true
      );
      const activeKeyDefault = tabDefault
        ? tabDefault.uid
        : listTabBodySortOrder[0]?.uid;
      return activeKeyDefault;
    }
  }, [activeKeyTab, listTabBodySortOrder]);

  const tabBodyActive =
    listTabBodySortOrder && activeKey
      ? listTabBodySortOrder.find((x) => x.uid === activeKey)
      : null;
  const listBlockBody = tabBodyActive
    ? tabBodyActive.block_list
    : listTabBodySortOrder[0].block_list;

  const listBlocksData =
    country === COUNTRY_TH
      ? listBlockBody?.filter(
          (block) => !COUNTRY_TH_BLOCK_HIDDEN.includes(block?.block_type)
        )
      : listBlockBody;

  const listBlocksSortOrder =
    listBlocksData && orderBy(listBlocksData, ["sort_order"], ["asc"]);

  const positionSlug = bodyState && bodyState.slug;

  const isDropDisabled =
    (!bodyDrag && !addNewBlockButtonDrag) ||
    (addNewBlockButtonDrag && listBlocksSortOrder?.length === 0);

  return (
    <>
      <Divider
        contentPosition="left"
        className={cls(
          "body-design divider-design ",
          PRODUCT_TOUR_CLASS_NAME.STEP_7
        )}
      >
        <div
          className="flex justify-center items-center font-normal"
          id={"content_blocks_body"}
        >
          {t("ss_builder_body_title")}
          <PositionInfo
            actionBtn={
              <div className="cursor-pointer">
                <InfomationPositionIcon
                  width={13}
                  height={13}
                  className="ml-1"
                />
              </div>
            }
            info={{
              image:
                "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-7.gif",
              title: t("ss_builder_body_introduction_title"),
              desctiption: t("ss_builder_body_introduction_description"),
            }}
          />
        </div>
      </Divider>
      <Droppable
        droppableId={
          positionSlug ? `designs-${positionSlug}` : "designs-body-default"
        }
        isDropDisabled={isDropDisabled}
      >
        {(provided, snapshot): JSX.Element => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="relative"
          >
            {listBlocksSortOrder?.length > 0 ? (
              listBlocksSortOrder.map((block, index) =>
                !BLOCK_NOT_DISPLAY.includes(block.block_type) ? (
                  <section
                    id={block.uid}
                    key={block.uid}
                  >
                    <Draggable
                      draggableId={`designs-${block.uid}`}
                      index={index}
                      key={`designs-${block.uid}`}
                    >
                      {(provided, snapshot): JSX.Element => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={cls(
                            // "outside-space",
                            "block-cursor-default",
                            snapshot.isDragging &&
                              "adm-item-custom-design bg-drag-drop blur-none"
                          )}
                        >
                          <BlockDraggingSpace
                            addNewBlockButtonDrag={addNewBlockButtonDrag}
                          >
                            <MappingTypeComponent
                              blockData={block}
                              // key={index}
                              positionSlug={positionSlug}
                              tabUid={activeKey}
                              index={index}
                              listBlocksSortOrder={listBlocksSortOrder}
                            />
                          </BlockDraggingSpace>
                          {!addNewBlockButtonDrag && (
                            <Divider
                              style={{
                                color: "#1890FF",
                                borderColor: "#1890FF",
                                borderStyle: "dashed",
                                height: `${block_space}px`,
                                margin: "0px",
                                visibility:
                                  bodyDrag ||
                                  addNewBlockButtonDrag ||
                                  index === listBlocksSortOrder.length - 1
                                    ? "hidden"
                                    : "unset",
                                opacity: !isDropDisabled ? 0 : 1,
                                paddingLeft: outside_space
                                  ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
                                  : "",
                                paddingRight: outside_space
                                  ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
                                  : "",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  </section>
                ) : null
              )
            ) : (
              <EmptyBlock
                data={{ position: "body", uid: tabBodyActive?.uid }}
              />
            )}
            {provided.placeholder}
            {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
              <PlaceholderCustom
                placeholderProps={placeholderProps}
                isFullBlock={listBlocksSortOrder?.length >= 10}
              />
            )}
          </div>
        )}
      </Droppable>
      <div
        style={{
          height: `${block_space}px`,
          marginTop: `-${block_space}px`,
          marginBottom: `-${block_space}px`,
        }}
      />
    </>
  );
};

export default BodyDesign;
