import { useAppDispatch, useAppSelector } from "@app/hooks";
import {
  BLOCK_NOT_DISPLAY,
  BLOCK_SETTING_DEFAULT_VALUE,
  COUNTRY_TH,
} from "@share/configs/const";
import { Divider, Switch } from "antd-mobile";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import React, { useMemo } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import MappingTypeComponent from "../../MappingTypeComponent";
import cls from "classnames";
import { useTranslation } from "react-i18next";
import { disableEnablePosition } from "@features/main/store/positionsSlice";
import gaEvents, { ACTION_CONFIG, CATEGORY_CONFIG } from "@share/lib/ga-events";
import { PRODUCT_TOUR_CLASS_NAME } from "../../ProductTour/product-tour-config";
import PositionInfo from "@features/design/components/PositionInfo";
import { InfomationPositionIcon } from "@share/icons";
import EmptyBlock from "../../EmptyBlock";
import PlaceholderCustom from "../PlaceholderCustom";
import BlockDraggingSpace from "../BlockDraggingSpace";
import { useSelector } from "react-redux";
import { RootState } from "@app/store";
import { COUNTRY_TH_BLOCK_HIDDEN } from "@features/block/config/block-selection";

const FooterDesign = (props) => {
  const { footerState, footerDrag, addNewBlockButtonDrag, placeholderProps } =
    props;
  const block_style = useAppSelector(
    (state) => state.siteSettings?.display_setting?.block_setting?.block_style
  );
  const country = useSelector((state: RootState) => state?.user?.kol?.country);
  const {
    block_space = BLOCK_SETTING_DEFAULT_VALUE.BLOCK_SPACE,
    outside_space,
  } = block_style ?? {};

  const listBlocksSortOrder = useMemo(() => {
    const listBlocksData =
      country === COUNTRY_TH
        ? footerState?.block_list?.filter(
            (block) => !COUNTRY_TH_BLOCK_HIDDEN.includes(block?.block_type)
          )
        : footerState?.block_list;
    const newListBlocksData =
      listBlocksData && orderBy(listBlocksData, ["sort_order"], ["asc"]);
    return newListBlocksData;
  }, [footerState, country]);

  const positionSlug = footerState && footerState.slug;
  const isChecked = useMemo(
    () => !!(footerState && footerState.enable && footerState.enable === 1),
    [footerState]
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const passioPageProfile = useAppSelector((state) => state.passioPageProfile);

  const handleChangeSwitch = (value: boolean) => {
    if (value) {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.POSITION,
        "Footer_TurnOn",
        "Footer",
        ACTION_CONFIG.TURN_ON
      );
    } else {
      gaEvents.submitEventV2(
        passioPageProfile,
        CATEGORY_CONFIG.POSITION,
        "Footer_TurnOff",
        "Footer",
        ACTION_CONFIG.TURN_OFF
      );
    }
    const enableValue = value ? 1 : 0;
    dispatch(
      disableEnablePosition({ enableValue, positionSlug: positionSlug })
    );
  };

  const isDropDisabled =
    (!footerDrag && !addNewBlockButtonDrag) ||
    (addNewBlockButtonDrag && listBlocksSortOrder?.length === 0);

  return (
    <>
      <Divider
        contentPosition="left"
        className={cls(
          "footer-design divider-design adm-default ",
          PRODUCT_TOUR_CLASS_NAME.STEP_8,
          isChecked ? "" : "hidden-divider"
        )}
      >
        <div className="flex justify-center items-center font-normal">
          {t("ss_builder_footer_title")}

          <PositionInfo
            actionBtn={
              <div className="cursor-pointer">
                <InfomationPositionIcon
                  width={13}
                  height={13}
                  className="ml-1"
                />
              </div>
            }
            info={{
              image:
                "https://image.passio.eco/page-builder/page-builder/product-tour/product-tour-8.gif",
              title: t("ss_builder_footer_introduction_title"),
              desctiption: t("ss_builder_footer_introduction_description"),
            }}
          />
        </div>
        <div className="absolute top-0 right-[var(--block-outside-space)]">
          <Switch
            style={{
              "--height": "18px",
              "--width": "34px",
            }}
            checked={isChecked}
            onChange={handleChangeSwitch}
          />
        </div>
      </Divider>
      <div
        className={cls("position-wrapper", isChecked ? "" : "hide-position")}
      >
        <Droppable
          droppableId={
            positionSlug ? `designs-${positionSlug}` : "designs-footer-default"
          }
          isDropDisabled={isDropDisabled}
        >
          {(provided, snapshot): JSX.Element => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="relative"
            >
              {listBlocksSortOrder?.length > 0 ? (
                listBlocksSortOrder.map((block, index) =>
                  !BLOCK_NOT_DISPLAY.includes(block.block_type) ? (
                    <section
                      id={block.uid}
                      key={block.uid}
                    >
                      <Draggable
                        draggableId={`designs-${block.uid}`}
                        index={index}
                        key={`designs-${block.uid}`}
                      >
                        {(provided, snapshot): JSX.Element => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={cls(
                              "block-cursor-default",
                              snapshot.isDragging &&
                                "adm-item-custom-design bg-drag-drop blur-none"
                            )}
                          >
                            <BlockDraggingSpace
                              addNewBlockButtonDrag={addNewBlockButtonDrag}
                            >
                              <MappingTypeComponent
                                blockData={block}
                                // key={index}
                                positionSlug={positionSlug}
                                index={index}
                                listBlocksSortOrder={listBlocksSortOrder}
                              />
                            </BlockDraggingSpace>
                            {!addNewBlockButtonDrag && (
                              <Divider
                                style={{
                                  color: "#1890FF",
                                  borderColor: "#1890FF",
                                  borderStyle: "dashed",
                                  height: `${block_space}px`,
                                  margin: "0px",
                                  visibility:
                                    footerDrag ||
                                    addNewBlockButtonDrag ||
                                    index === listBlocksSortOrder.length - 1
                                      ? "hidden"
                                      : "unset",
                                  opacity: !isDropDisabled ? 0 : 1,
                                  paddingLeft: outside_space
                                    ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
                                    : "",
                                  paddingRight: outside_space
                                    ? `${BLOCK_SETTING_DEFAULT_VALUE.OUTSIDE_SPACE_NUMBER}px`
                                    : "",
                                }}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    </section>
                  ) : null
                )
              ) : (
                <EmptyBlock data={{ position: "footer", uid: "" }} />
              )}
              {provided.placeholder}
              {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                <PlaceholderCustom
                  placeholderProps={placeholderProps}
                  isFullBlock={listBlocksSortOrder?.length >= 4}
                />
              )}
            </div>
          )}
        </Droppable>
        <div
          style={{
            height: `${block_space}px`,
            marginTop: `-${block_space}px`,
            marginBottom: `-${block_space}px`,
          }}
        />
      </div>
    </>
  );
};

export default FooterDesign;
